<template>
	<div class='course-content'>
		<div class='course-top overflow'>
			<!-- <div class="float-left">
				<div class="course-title">我的参与课程</div>
			</div> -->
			<div class="float-left overflow-inherit" style="height: 100%;margin-top: 13px;">
				<div :class="isActive == 'my' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					@click="tabs('my')">我的{{region_theme_text}}</div>
				<div :class="isActive == 'all' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					style="margin-left: 20px;" @click="tabs('all')">全部{{region_theme_text}}</div>
			</div>
			<div class="float-right">
				<div class="custom-btn btn-corlor-first float-right" @click="store">新增参赛{{region_theme_text}}</div>
				<button class="edit-btn float-right" @click="edit">
					<span><img src="../assets/img/no-edit.png"></span>
					<span>返回首页</span>
				</button>
			</div>
		</div>
		<div>
			<div class="table-content" v-loading="loading">
				<el-table :data="tabList" border stripe :header-cell-style="{background: '#EEF7FE',color:'black'}" style="width: 100%;">
					<el-table-column prop="num" label="编码" align="center" width="170">
						
					</el-table-column>
					<el-table-column :label="util.getKeyFrontValue('cover')" width="120" align="center">
						<template slot-scope="scope">
							<el-popover placement="right" title="" trigger="hover" width="250" style="cursor: pointer;">
								<el-image slot="reference" :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"
									style="height: 75px;width:60px"></el-image>
								<el-image :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"></el-image>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="name" :label="util.getKeyFrontValue('name')" min-width="200">
						<template slot-scope="scope">
							<div>
								<div class="row-name">{{ scope.row.name }}</div>
								<span v-if="scope.row.relate_course_category && scope.row.relate_course_category.kind == 'labor'" class="labor-label comment-margin-right-5">劳动教育</span>
								<span v-if="scope.row.relate_course_category && scope.row.relate_course_category.kind == 'comprehensive'" class="comprehensive-label comment-margin-right-5">综合实践</span>
								<span class="stage-label">
									{{scope.row.apply_to_stages && scope.row.apply_to_stages.length>0 ? util.setStage(scope.row.apply_to_stages) : ''}}
								</span>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="course_category_id" :label="util.getKeyFrontValue('category')" align="center" width="130">
						<template slot-scope="scope">
							<div>
								{{scope.row.relate_course_category ? scope.row.relate_course_category.name : ''}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="apply_to_stages" :label="util.getKeyFrontValue('apply')" align="center" width="100">
						<template slot-scope="scope">
							{{scope.row.apply_to_stages && scope.row.apply_to_stages.length>0 ? util.setStage(scope.row.apply_to_stages) : ''}}
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="voted_num" label="点赞数" align="center" width="100">
						<template slot-scope="scope">
							{{ scope.row.voted_num ? scope.row.voted_num : 0 }}
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="hour" label="学时" align="center" width="50">
					</el-table-column> -->
					<!-- <el-table-column prop="opinion_sharing" label="课程简介" align="center">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" width="600" style="cursor: pointer;">
								<p>课程简介: {{ scope.row.opinion_sharing }}</p>
								<span slot="reference" class="name-wrapper">
									{{ (scope.row.opinion_sharing+'').length > 50 ? scope.row.opinion_sharing.substr(0, 50) + "..." + '' : scope.row.opinion_sharing}}
								</span>
							</el-popover>
						</template>
					</el-table-column> -->
					<el-table-column prop="relate_competition_course_comment_content.content" label="专家评语" min-width="200">
						<template slot-scope="scope">
							{{ scope.row.relate_competition_course_comment_content ? scope.row.relate_competition_course_comment_content.content : '--' }}
						</template>
					</el-table-column>
					<el-table-column prop="confirm_status" label="状态" align="center" width="120">
						<template slot-scope="scope">
							<div v-if="scope.row.confirm_status == util.confirm_status[9].code" style="color: red;cursor: pointer;">
								<el-popover trigger="hover" placement="top">
									<p>驳回原因: {{ scope.row.reason }}</p>
									<span slot="reference" class="name-wrapper">
										<el-tag size="medium">
											<div style="color: red;">{{util.confirm_status[scope.row.confirm_status].key}}</div>
										</el-tag>
									</span>
								</el-popover>
							</div>
							<div v-else>
								{{scope.row.confirm_status ? util.confirm_status[scope.row.confirm_status].key : ''}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="150" fixed="right" align="center">
						<template slot-scope="scope">
							<!-- <el-button type="text" @click='deleteList(scope.row)'>评分</el-button> -->
							<el-button v-if="scope.row.confirm_status == 8 || scope.row.confirm_status == 10 || scope.row.confirm_status == 13" type="text" style="color: #44D89E;" @click='detailClick(scope.row)'>详情</el-button>
							<el-button v-if="scope.row.confirm_status == 9" type="text" style="color: red;" @click='seeReason(scope.row)'>查看原因</el-button>
							<el-button v-if="scope.row.confirm_status == 9 || scope.row.confirm_status == 13" type="text" @click='update(scope.row)'>编辑</el-button>
							<!-- <el-button v-if="scope.row.confirm_status == 8" type="text" style="color: red;" @click='revoke(scope.row)'>撤回</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="block">
				<el-pagination v-if='paginationShow' background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="currentPage">
				</el-pagination>
			</div>
		</div>
		<courseDetail ref="courseDetail" role="staff"></courseDetail>
	</div>
</template>

<script>
	import courseDetail from '../components/courseDetail.vue'
	import {
		reactive,
		ref,
		toRefs,
		watchEffect
	} from 'vue'
	import {
		useRouter
	} from 'vue-router' //引用router
	export default {
		name: 'index',
		components: {
			courseDetail
		},
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				isActive: 'my',
				loading: false,
				tabList: [],
				pageSize: 5,
				total: 1,
				currentPage: 1,
				competition_status: '',
				paginationShow: false
			}
		},
		methods: {
			editLogin() {
				localStorage.clear()
				sessionStorage.clear()
				// 退出
				this.$router.push({
					name: 'login'
				})
				history.pushState(null, null, document.URL);
				window.addEventListener("popstate", function(e) {
					history.pushState(null, null, document.URL);
				}, false);
			},
			// 详情
			detailClick(row) {
				this.$refs.courseDetail.show(row)
				// this.$router.push({
				// 	name: 'review_detail',
				// 	query: {
				// 		course_id: row.id
				// 	}
				// })
			},
			// 查看原因
			seeReason(row) {
				this.$alert(row.reason, '拒绝原因', {
				  confirmButtonText: '确定',
				  confirmButtonClass: 'confirmCancelClass',
				  callback: action => {
				    
				  }
				});
			},
			//编辑
			update(row) {
				this.$router.push({
					name: 'store',
					query: {
						id: row.id
					}
				})
			},
			// 撤销
			revoke(row) {
				this.$confirm('是否确定撤回该' + this.region_theme_text +'?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					// type: 'warning'
				}).then(() => {
					this.http.courseRevoke('?id=' + row.id).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '已撤回!'
							});
							this.getList()
						} else {
							this.$message.error(res.data.msg);
						}
					})
				}).catch(() => {
					
				});
			},
			//返回
			edit() {
				this.$router.push({
					name: 'index'
				})
			},
			//添加
			store() {
				this.$router.push({
					name: 'store'
				})
			},
			//删除
			deleteList(row) {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let obj = {
						ids: [row.id]
					}
					this.http.deleteList('', obj).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							let totalPag = Math.ceil((this.total - 1) / this.pageSize)
							let currtPage = this.currentPage > totalPag ? totalPag : this.currentPage
							this.currentPage = currtPage < 1 ? 1 : currtPage
							this.getList()
						} else {
							this.$message.error(res.data.msg);
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				sessionStorage.setItem('currentPage', val);
				this.getList();
			},
			tabs(name) {
				if (this.isActive != name) {
					this.currentPage = 1
					this.isActive = name
					this.getList()
				}
			},
			getList() {
				// if (sessionStorage.getItem('store') && sessionStorage.getItem("currentPage")) {
				// 	//如果有这个就读取缓存里面的数据
				// 	this.currentPage = Number(sessionStorage.getItem("currentPage"));
				// }
				let obj = {
					page: this.currentPage,
					limit: this.pageSize
				}
				this.loading=true;
				let str = this.isActive == 'all' ? '&is_all=1' : ''
				this.http.courseList('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')) + str, obj).then((res) => {
					this.tabList = res.data.data.list
					this.total = res.data.data.total
					this.paginationShow = true
					this.loading=false;
				})
			},
			// 判断活动是否开启
			getConfig() {
				let key = '?key=' + this.util.config.competition_status
				this.http.config_detail(key, '').then((res) => {
					this.competition_status = res.data.data.competition_config
				})
			}
		},
		mounted() {
			this.getList()
			// this.getConfig()
		},
		destroyed() {
			sessionStorage.removeItem("store")
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.course-content {
		width: 68%;
		min-width: 1100px;
		height: 73vh;
		margin: 0 auto;
		background-color: white;
		padding: 20px 10px 20px 25px;
		box-sizing: border-box;
		overflow-y: scroll;
		/* box-shadow: 0 0 100px rgba(31, 180, 249, 0.26); */
	}

	.course-top {
		height: 60px;
		padding-right: 13px;
	}

	.course-title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}

	.el-table td,
	.el-table th {
		padding: 8px 0 !important;
	}

	.table-content {
		/* min-height: 49vh;
		max-height: 48vh; */
		min-height: 49vh;
		overflow-y: scroll;
	}

	.table-content /deep/ .el-table td {
		padding: 6px 0 !important
	}

	.table-content /deep/ .el-table th {
		padding: 6px 0 !important
	}

	/* 分页 */
	.block {
		text-align: center;
		margin-top: 20px;
	}
	/*表格hover*/
	/deep/.el-tag {
		border-radius: 0;
		background: none;
		border: none;
		color: black;
		font-size: 14px;
		/*line-height: 60px;*/
	}
	/*切换样式*/
	.tabs-btn {
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		/* margin-right: 20px; */
		cursor: pointer;
	}
	.tabs-btn-true {
		position: relative;
		font-size: 16px;
		font-weight: bold;
		color: #FF3B3D;
		/* margin-right: 20px; */
		cursor: pointer;
	}
	.tabs-btn-true::after {
		content: '';
		height: 2px;
		width: 70%;
		display: block;
		position: absolute;
		background-color: #FF3B3D;
		left: 15%;
		bottom: -6px;
	}
	.row-name {
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 10px;
	}
	.labor-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #EBFFF7;
		text-align: center;
		border-radius: 1px;
		color: #44D89E;
		font-size: 10px;
		line-height: 14px;
	}
	.comprehensive-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #FFF1EB;
		text-align: center;
		border-radius: 1px;
		color: #FB824E;
		font-size: 10px;
		line-height: 14px;
	}
	.stage-label {
		display: inline-block;
		min-width: 50px;
		height: 14px;
		padding: 2px 3px;
		background: #EFF6FF;
		text-align: center;
		border-radius: 1px;
		color: #1950A2;
		font-size: 10px;
		line-height: 14px;
	}
</style>
